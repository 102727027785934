import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { heroConfig } from "../config/hero.config";

const Hero = () => {
  return (
    <div className="relative min-h-screen bg-[#030305] overflow-hidden">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-gray-900 via-[#030305] to-black"></div>
      <div
        className="absolute inset-0 opacity-[0.15] mix-blend-overlay"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
        }}
      ></div>
      <div className="absolute inset-0">
        <div className="absolute -top-1/2 left-1/4 w-[1000px] h-[1000px] bg-[#6439FF]/10 rounded-full blur-[120px] mix-blend-screen"></div>
        <div className="absolute -bottom-1/2 right-1/4 w-[800px] h-[800px] bg-[#00CCDD]/10 rounded-full blur-[120px] mix-blend-screen"></div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
          className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent via-[#4F75FF]/5 to-black/50"
        ></motion.div>
      </div>
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#000_1px,transparent_1px),linear-gradient(to_bottom,#000_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black_70%)] opacity-20"></div>
      </div>
      <div className="relative desktop:px-20 laptop:px-16 tablet:px-8 mobile:px-4 desktop:py-32 laptop:py-24 tablet:py-20 mobile:py-16">
        <div className="desktop:max-w-3xl laptop:max-w-2xl tablet:max-w-xl mobile:max-w-xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="relative z-10"
          >
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "10rem" }}
              transition={{ duration: 1, delay: 0.5 }}
              className="absolute -left-6 top-0 w-[2px] bg-gradient-to-b from-[#6439FF] via-[#4F75FF]/50 to-transparent"
            ></motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="mb-4"
            >
              <img
                src={heroConfig.logo.src}
                alt={heroConfig.logo.alt}
                className="desktop:h-16 laptop:h-16 tablet:h-14 mobile:h-12 w-auto"
              />
            </motion.div>
            <h1 className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl font-bold desktop:mt-8 laptop:mt-8 tablet:mt-6 mobile:mt-6 desktop:mb-8 laptop:mb-8 tablet:mb-6 mobile:mb-6">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.3 }}
                className="relative inline-block"
              >
                <span className="block bg-gradient-to-r from-white via-gray-200 to-gray-400 bg-clip-text text-transparent">
                  {heroConfig.headline.main}
                </span>
                <div className="absolute -inset-1 bg-[#4F75FF]/20 blur-xl"></div>
              </motion.div>
              <motion.span
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.5 }}
                className="block bg-gradient-to-r from-[#4F75FF] via-[#00CCDD] to-[#7CF5FF] bg-clip-text text-transparent"
              >
                {heroConfig.headline.highlight}
              </motion.span>
            </h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.7 }}
              className="desktop:text-xl laptop:text-lg tablet:text-base mobile:text-sm text-gray-400 mb-12 leading-relaxed"
            >
              {heroConfig.description}
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.9 }}
              className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col gap-4"
            >
              <a
                href={heroConfig.cta.primary.path}
                className="group relative desktop:text-lg laptop:text-lg tablet:text-base mobile:text-sm font-semibold rounded-xl px-8 py-4"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-[#6439FF] to-[#00CCDD] rounded-xl"></div>
                <div className="absolute inset-0 bg-gradient-to-r from-[#6439FF] to-[#00CCDD] rounded-xl opacity-0 group-hover:opacity-100 blur-xl transition-opacity duration-500"></div>
                <div className="absolute inset-[1px] rounded-[10px] bg-gradient-to-r from-[#6439FF]/20 to-[#00CCDD]/20 group-hover:from-[#6439FF]/30 group-hover:to-[#00CCDD]/30"></div>
                <span className="relative z-10 text-white flex items-center justify-center gap-2">
                  {heroConfig.cta.primary.text}
                  <svg
                    className="w-5 h-5 transition-transform duration-300 group-hover:translate-x-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </span>
              </a>
              <a
                href={heroConfig.cta.secondary.path}
                className="group relative desktop:text-lg laptop:text-lg tablet:text-base mobile:text-sm font-semibold rounded-xl px-8 py-4"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-gray-800/50 to-gray-700/50 rounded-xl"></div>
                <div className="absolute inset-[1px] rounded-[10px] bg-gradient-to-r from-gray-950 to-gray-900"></div>
                <span className="relative z-10 text-white flex items-center justify-center gap-2">
                  {heroConfig.cta.secondary.text}
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </a>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 1.1 }}
              className="mt-16 grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-3 mobile:grid-cols-1 gap-6"
            >
              {heroConfig.stats.map((stat, index) => (
                <div key={index} className="relative group">
                  <div className="absolute inset-0 bg-gradient-to-r from-[#6439FF]/20 to-[#00CCDD]/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                  <div className="relative bg-gradient-to-r from-gray-900/90 to-gray-800/90 backdrop-blur-xl rounded-xl p-4 border border-gray-800/50">
                    <div className="text-gray-400 text-sm">{stat.label}</div>
                    <div className="text-white text-3xl font-bold mt-1">
                      {stat.value}
                    </div>
                    <div className="text-gray-500 text-sm mt-1">
                      {stat.subtext}
                    </div>
                  </div>
                </div>
              ))}
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
