export const heroConfig = {
  logo: {
    src: "https://createsiteai.b-cdn.net/c/_/b8ba12f7-c924-4151-963b-1c3f5bbe36a4.svg",
    alt: "Mastercharge.io Logo",
  },
  headline: {
    main: "Powering your",
    highlight: "EV Infrastructure",
  },
  description:
    "Our 360° solution simplifies operating EV charge stations, offering frictionless web and app payments for drivers, maximizing revenue with dynamic pricing tools, and ensuring high uptime through self-support and remote monitoring. Effortless, efficient, and profitable - it's all in one platform.",
  cta: {
    primary: {
      text: "Contact Us",
      path: "https://forms.gle/SUxemhBcVPBtBC797",
    },
    secondary: {
      text: "Schedule Demo",
      path: "https://forms.gle/SUxemhBcVPBtBC797",
    },
  },
  stats: [
    {
      label: "Servicing",
      value: "150K+",
      subtext: "Charge Stations",
    },
    {
      label: "Platform Uptime",
      value: "99.99%",
      subtext: "Reliability",
    },
    {
      label: "Energy Delivered",
      value: "15M+",
      subtext: "kWh Monthly",
    },
  ],
};
